<template>
  <v-form :readonly="value.id > 0 && !canEditSetupTables">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn
            v-if="canEditSetupTables"
            class="float-right text-white"
            color="green"
            :disabled="$v.form.$invalid"
            @click="onSubmit"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-expansion-panels accordion mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header>General</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="8">
              <v-text-field
                :value="value.name"
                v-model.trim="$v.form.name.$model"
                label="Name"
                required
                :error-messages="handleErrors($v.form.name)"
                @blur="$v.form.name.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                :value="value.active"
                v-model="$v.form.active.$model"
                label="Active"
                required
                @change="$v.form.active.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="10">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Funding Source Type"
                :value="value.type"
                v-model="$v.form.type.$model"
                :items="fsTypes"
                item-text="name"
                item-value="id"
                outlined
                :error-messages="handleErrors($v.form.type)"
                class="mb-0"
                @change="handleTypeChange"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="8" v-if="SINGLE_BUDGET_CODE_IDS.includes($v.form.type.$model)">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Budget Code"
                :value="value.budgetCode"
                v-model="$v.form.budgetCodeId.$model"
                :items="filteredBudgetCodes"
                item-text="description"
                item-value="id"
                outlined
                class="mb-0"
                clearable
                hide-details="auto"
              >
              </v-select>
              <div class="mt-2">{{ getBudgetCodeName($v.form.budgetCodeId.$model) }}</div>
            </v-col>
            <v-col cols="12" md="8" v-if="$v.form.type.$model == 4">
              <v-text-field
                :value="value.budgetCode"
                v-model="$v.form.budgetCode.$model"
                label="Budget Code"
                required
                @blur="$v.form.budgetCode.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="$v.form.type.$model === fundingSourceTypesMap.singleBudgetCodeAllotments">
              <v-checkbox
                :value="value.allotmentsUsed"
                v-model="$v.form.allotmentsUsed.$model"
                label="Allotments Used"
                required
                @change="$v.form.allotmentsUsed.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense v-if="$v.form.type.$model == 4">
            <v-col cols="12" md="10">
              <v-checkbox
                :value="value.codeIsEditable"
                v-model="$v.form.codeIsEditable.$model"
                label="Budget Code editable on Trip Request"
                required
                @change="$v.form.codeIsEditable.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="10">
              <v-textarea
                :value="value.description"
                v-model="$v.form.description.$model"
                auto-grow
                outlined
                label="Description"
                rows="2"
                @change="$v.form.allotmentsUsed.$touch()"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="10" class="mb-0">
              <user-select
                :value="value.approverId"
                v-model.trim="$v.form.approverId.$model"
                :users="users"
                label="Approver"
                :noChips="true"
                @handleUserSelect="handleUserSelect($event, 'approverId')"
              >
              </user-select>
            </v-col>
            <v-col cols="12" md="10" class="mb-0">
              <user-select
                :value="value.payerId"
                v-model="$v.form.payerId.$model"
                :users="users"
                label="Payment Manager"
                :noChips="true"
                @handleUserSelect="handleUserSelect($event, 'payerId')"
              >
              </user-select>
            </v-col>
            <v-col cols="12" md="10" class="mb-0">
              <v-combobox
                :value="value.notifyEmail"
                v-model="$v.form.notifyEmail.$model"
                :items="userEmails"
                label="Notification of Use"
                outlined
                :return-object="false"
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-checkbox
                :value="value.incOnFinance"
                v-model="$v.form.incOnFinance.$model"
                label="Include on School Finance"
                required
                @change="$v.form.incOnFinance.$touch()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                :value="value.locationSpecific"
                v-model="$v.form.locationSpecific.$model"
                label="Location Specific"
                required
                @change="$v.form.locationSpecific.$touch()"
              ></v-checkbox>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <v-checkbox
                :value="value.fundSpecific"
                v-model="$v.form.fundSpecific.$model"
                label="Fund Specific"
                required
                @change="$v.form.fundSpecific.$touch()"
              ></v-checkbox>
            </v-col> -->
          </v-row>

          <v-row dense v-if="$v.form.locationSpecific.$model">
            <v-col cols="12" md="10">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Locations"
                :value="value.locations"
                v-model="$v.form.locations.$model"
                :items="locations"
                item-text="name"
                item-value="id"
                outlined
                multiple
                class="mb-0"
              >
                <template v-slot:selection="{ item, parent }">
                  <v-chip>
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="10">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Display on these Trips"
                :value="value.displayOnTripTypes"
                v-model="displayOnTripTypes"
                :items="tripTypes"
                item-text="name"
                item-value="id"
                outlined
                multiple
                class="mb-0"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="displayOnTripTypes?.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, parent }">
                  <v-chip>
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="showBudgeting">
        <v-expansion-panel-header>Budgeting</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="6">
              <date-picker label="Budget Start Date" v-model="form.budget.startDate"></date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <date-picker label="Budget End Date" v-model="form.budget.endDate"></date-picker>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.budget.allotment"
                v-model="form.budget.allotment"
                label="Budget Allotment"
                type="number"
                min="0"
                prepend-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <div class="text-subtitle-2 font-weight-bold">Invoices Approved for Payment:</div>
              <div class="text-subtitle-2 font-weight-bold">Invoices Payment Sent:</div>
              <div class="text-subtitle-2 font-weight-bold">Invoices Paid:</div>
              <div class="text-subtitle-2 font-weight-bold">Balance:</div>
              <div class="text-subtitle-2 font-weight-bold">Invoices Pending:</div>
              <div class="text-subtitle-2 font-weight-bold">Est Driver Costs:</div>
              <div class="text-subtitle-2 font-weight-bold">Trips Without Reservations:</div>
              <div class="text-subtitle-2 font-weight-bold">Estimate Balance:</div>
            </v-col>
            <v-col cols="12" md="8">
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.invoicesApproved }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.invoicesPaymentSent }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.invoicesPaid }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.balance }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.invoicesPending }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.estDriverCosts }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.tripsNoRes }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ value.budget.estBalance }}</div>
              <!-- <div class="text-subtitle-2 font-weight-normal" v-html="value.budget.addressText"></div> -->
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import UserSelect from '@/components/UserSelect.vue';
import DatePicker from '@/components/DatePicker';
import { handleErrors, SINGLE_BUDGET_CODE_IDS } from '@/util';
import { fundingSourceMap } from '@/util/enums';
import { fundingSourceTypesMap } from '@/util/constants';

const newFundingSource = {
  name: '',
  type: 1,
  active: true,
  allotmentsUsed: false,
  budgetCode: '',
  budgetCodeId: 0,
  codeIsEditable: false,
  approverId: 0,
  payerId: 0,
  description: '',
  approverEmail: '',
  paymentEmail: '',
  notifyEmail: '',
  incOnFinance: true,
  locationSpecific: false,
  fundSpecific: false,
  locations: [],
  displayOnTripTypes: [],
  budget: {
    allotment: 0,
    startDate: null,
    endDate: null,
  },
};

export default {
  mixins: [validationMixin],
  components: { UserSelect, DatePicker },
  data: () => ({
    handleErrors,
    showBudgeting: false,
    id: null,
    form: newFundingSource,
    fsTypes: [
      fundingSourceMap.type.singleBasedOnLoc,
      fundingSourceMap.type.singleAllotment,
      fundingSourceMap.type.multiple,
      fundingSourceMap.type.editable,
    ],
    filteredBudgetCodes: [],
    saved: false,
    SINGLE_BUDGET_CODE_IDS: SINGLE_BUDGET_CODE_IDS,
    fundingSourceTypesMap,
    displayOnTripTypes: [],
  }),
  validations: {
    form: {
      name: { required },
      type: { required },
      active: {},
      allotmentsUsed: {},
      budgetCode: { maxLength: 50 },
      budgetCodeId: {},
      codeIsEditable: {},
      approverId: {},
      payerId: {},
      description: { maxLength: 1000 },
      approverEmail: { maxLength: 128 },
      paymentEmail: { maxLength: 128 },
      notifyEmail: { maxLength: 128 },
      incOnFinance: {},
      locationSpecific: {},
      fundSpecific: {},
      locations: {},
      displayOnTripTypes: {},
      budget: {
        startDate: {
          required: requiredIf(function () {
            return this.showBudgeting;
          }),
        },
        endDate: {
          required: requiredIf(function () {
            return this.showBudgeting;
          }),
        },
        allotment: {
          required: requiredIf(function () {
            return this.showBudgeting;
          }),
        },
      },
    },
  },
  computed: {
    ...mapGetters('location', ['locations']),
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    userEmails() {
      return [
        { header: 'Select a user or enter an email address' },
        ...this.users.map((e) => ({ text: `${e.displayName} (${e.email})`, value: e.email })),
      ];
    },
    selectAllTripTypes() {
      return this.displayOnTripTypes?.length === this.tripTypes?.length;
    },
    selectSomeTripTypes() {
      return this.displayOnTripTypes?.length > 0 && !this.selectAllTripTypes;
    },
    icon() {
      if (this.selectAllTripTypes) return 'mdi-close-box';
      if (this.selectSomeTripTypes) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    getBudgetCodeName(budgetCodeId) {
      return this.budgetCodes.find((bc) => bc.id === budgetCodeId)?.name;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllTripTypes) {
          this.displayOnTripTypes = [];
        } else {
          this.displayOnTripTypes = this.tripTypes.slice();
        }
      });
    },
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newFundingSource;
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch();
      }

      const selectedBudgetCode = this.filteredBudgetCodes.find((bc) => bc.id === this.form.budgetCodeId);

      const toSave = {
        id: this.id,
        ...this.$v.form.$model,
        budgetCodeId: selectedBudgetCode?.id || 0,
        budgetCode: selectedBudgetCode?.description?.trim() || this.$v.form.budgetCode.$model || '',
        displayOnTripTypes:
          Array.isArray(this.displayOnTripTypes) && typeof this.displayOnTripTypes[0] === 'object'
            ? this.displayOnTripTypes.map((t) => t.id)
            : this.displayOnTripTypes,
      };

      if (toSave.type !== fundingSourceTypesMap.singleBudgetCodeAllotments) toSave.allotmentsUsed = null;
      if (!toSave.approverId) toSave.approverEmail = '';
      if (!Number.isInteger(toSave.approverId)) {
        toSave.approverEmail = toSave.approverId;
        delete toSave.approverId;
      }
      if (!Number.isInteger(toSave.payerId)) {
        toSave.payerEmail = toSave.payerId;
        delete toSave.payerId;
      }

      this.$emit('submit', toSave);
      this.saved = true;
    },
    handleTypeChange(type) {
      this.showBudgeting = false;

      if (SINGLE_BUDGET_CODE_IDS.includes(type)) {
        this.showBudgeting = true;
        let filtered = [];

        if (type === 1) filtered = this.budgetCodes.filter((bc) => Object.values(bc.code).join('').includes('?'));
        else if (type === 2) filtered = this.budgetCodes.filter((bc) => !Object.values(bc.code).join('').includes('?'));

        this.filteredBudgetCodes = filtered;
      }
      this.$v.form.type.$touch();
    },
    handleUserSelect(users, field) {
      this.$v.form[field].$model = users;
      this.$v.form.$touch();
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.name || this.$v.form.$model.type)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.name != this.value.name ||
        this.$v.form.$model.type != this.value.type ||
        this.$v.form.$model.active != this.value.active ||
        this.$v.form.$model.allotmentsUsed != this.value.allotmentsUsed ||
        this.$v.form.$model.budgetCode != this.value.budgetCode ||
        this.$v.form.$model.codeIsEditable != this.value.codeIsEditable ||
        this.$v.form.$model.approverId != this.value.approverId ||
        this.$v.form.$model.payerId != this.value.payerId ||
        this.$v.form.$model.description != this.value.description ||
        this.$v.form.$model.incOnFinance != this.value.incOnFinance ||
        this.$v.form.$model.locationSpecific != this.value.locationSpecific ||
        this.$v.form.$model.fundSpecific != this.value.fundSpecific ||
        this.$v.form.$model.locations != this.value.locations ||
        this.$v.form.$model.displayOnTripTypes != this.value.displayOnTripTypes ||
        ((this.$v.form.$model.type == 2 || this.$v.form.$model.allotmentsUsed) &&
          (this.$v.form.$model.budget.allotment != this.value.budget.allotment ||
            this.$v.form.$model.budget.startDate != this.value.budget.startDate ||
            this.$v.form.$model.budget.endDate != this.value.budget.endDate))
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => newFundingSource,
    },
    budgetCodes: {
      type: Array,
      default: () => [],
    },
    tripTypes: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          if (!value.budget) value.budget = {};
          if (!value.approverId && value.approverEmail) value.approverId = value.approverEmail;
          if (!value.payerId && value.payerEmail) value.payerId = value.payerEmail;
          value.allotmentsUsed = Number(value.allotmentsUsed) === 1;
          this.displayOnTripTypes = value.displayOnTripTypes;
          this.form = { ...newFundingSource, ...value };
          this.handleTypeChange(value.type);
        }
      },
    },
  },
  mounted() {
    this.handleTypeChange(this.$v.form.type.$model);
  },
};
</script>

<style scoped>
.text-white {
  color: white;
}
</style>
